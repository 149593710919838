/* tslint:disable:max-line-length */
import {environment} from '../../environments/environment';
import {Order} from '../models/account/dto/order';
import {OnboardingComment} from '../interfaces/notes/onboarding-comments';
import {DriverInterface} from '../interfaces/driver/driverInterface';
import {OnboardingStatus} from "../views/onboarding/onboarding-status";
import { EmailLogRequest } from '../models/account/requests/email-log-request';
import { UnsubscribeEmailRequest } from '../models/account/requests/UnsubscribeEmailRequest';
import { ReturnOrder } from '../models/account/dto/return-order';
import { UnsubscribeSMSRequest } from '../models/account/requests/unsubscribe-sms-request';
import { SMSLogRequest } from '../models/account/requests/SMSLogRequest';
import { OrderWeightChargeRequest } from '../models/account/requests/updateOrderWeightCharge-request';
import { RushOrderRequest } from '../models/account/requests/rush-order-request';

export class Endpoints {

  // Session

  static encodeParam(s: string): string {
    return encodeURIComponent(s);
  }

  static login(companyId: number, email: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/users/${email}/login`;
  }

  static changePassword(companyId: number, email: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/users/${email}/changePassword`;
  }

  static resetForgottenPassword(companyId: number, email: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/users/${email}/resetforgottenpassword`;
  }

  static getForgotPasswordCode(companyId: number, email: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/users/${email}/forgotpasswordcode`;
  }

  static refreshSession(companyId: number, email: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/users/${email}/refreshsession`;
  }

  static signOut(companyId: number, email: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/users/${email}/logout`;
  }

  static getZendeskToken(email: string, companyId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/users/${email}/zendesktoken`;
  }

  // Driver Auth

  static driverLogin(email: string): string {
    return `${environment.mainServiceUrl}/drivers/${email}/login`;
  }

  static driverSignOut(email: string): string {
    return `${environment.mainServiceUrl}/drivers/${email}/logout`;
  }

  static driverRefreshSession(email: string): string {
    return `${environment.mainServiceUrl}/drivers/${email}/refreshsession`;
  }

  static driverResetForgottenPassword(email: string): string {
    return `${environment.mainServiceUrl}/drivers/${email}/resetforgottenpassword`;
  }

  static driverGetForgotPasswordCode(email: string): string {
    return `${environment.mainServiceUrl}/drivers/${email}/forgotpasswordcode`;
  }

  static driverGetZendeskToken(email: string): string {
    return `${environment.mainServiceUrl}/drivers/${email}/zendesktoken`;
  }

  static driverCheckPhone(phone: string, id?: number): string {
    let url = `${environment.mainServiceUrl}/drivers/check/phone/${phone}`;
    if (id){
      url += `?driverId=${id}`;
    }
    return url;
  }
  static driverCheckEmail(email: string): string {
    return `${environment.mainServiceUrl}/drivers/check/email/${email}`;
  }

  static getHydratedDRiver(id: number) {
    return `${environment.mainServiceUrl}/hydrateddrivers/${id}`;
  }

  static updateDriverOnboardingComment(driver: DriverInterface, comment: OnboardingComment) {
    return `${environment.mainServiceUrl}/drivers/${driver.id}/onboardingcomments/${comment.id}`;
  }
  static createDriverOnboardingComment(driver: DriverInterface) {
    return `${environment.mainServiceUrl}/drivers/${driver.id}/onboardingcomments`;
  }

  // Asset

  static deleteAsset(id, md5Hash: string): string {
    return `${environment.mainServiceUrl}/delete-asset?Id=${id}&MD5Hash=${md5Hash}`;
  }

  static generateUploadUrl(): string {
    return `${environment.mainServiceUrl}/generate-upload-url`;
  }

  static getPreview(locationId: number, displayId, menuId: string, returnLastSaved: boolean): string {
    return `${environment.mainServiceUrl}/get-preview?LocationId=${locationId}&DisplayId=${displayId}&ConfigurationId=${menuId}&ReturnLastSaved=${returnLastSaved}`;
  }

  static getAsset(id, md5Hash: string): string {
    return `${environment.mainServiceUrl}/get-asset?Id=${id}&MD5Hash=${md5Hash}`;
  }

  // Users

  static getCompanyUsers(companyId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/users`;
  }

  static getCompanyUser(companyId: number, userId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/users/${userId}`;
  }

  static createCompanyUser(companyId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/users`;
  }

  static updateCompanyUser(companyId: number, userId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/users/${userId}`;
  }

  static updateCompanyUserPassword(companyId: number, userEmail: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/users/${userEmail}/changepassword`;
  }
  //Email Logs

  static getCompanyEmailLogs(companyId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/emailLog`;
  }

  static getCompanySMSLogs(companyId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/smsLog`;
  }
  
  static getCompanyDetailsEmailLogs(companyId: number, locationId:number, emailLogsId:number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/emailLogById/${emailLogsId}`;
  }

  static getCompanyDetailsSMSLogs(companyId: number, locationId:number, smsLogsId:number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/smsLogById/${smsLogsId}`;
  }
  //unSubscribed Email

  static getCompanyUnSubscribedEmail(companyId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/unsubscribedEmail`;
  }
  static getCompanyUnSubscribedSMS(companyId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/unsubscribedPhone`;
  }
  static getCompanyUnsubscribedDetails(companyId: number,  unSubscribeDetailsId:number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/unsubscribedEmail/${unSubscribeDetailsId}`;
  }
  static getCompanyUnsubscribedSMSDetails(companyId: number,  unSubscribeDetailsId:number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/unsubscribedPhone/${unSubscribeDetailsId}`;
  }
  static resubscribedEmail(companyId: number, locationId:number, unSubscribeDetailsId:number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/subscribeEmail/${unSubscribeDetailsId}`;
  }

  static resubscribedSMS(companyId: number, locationId:number, unSubscribeDetailsId:number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/subscribePhone/${unSubscribeDetailsId}`;
  }

  static getCompanyUnsubcribePageDetail(companyId: number, locationId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/getCompanyLogo`;
  }

  static unsubcribeFromEmail(companyId: number, locationId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/unsubscribeEmail`;
  }
  static unsubcribeFromSMS(companyId: number, locationId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/unsubscribePhone`;
  }
  // Entities

  static getCompanies(): string {
    return `${environment.mainServiceUrl}/companies`;
  }

  static getCompany(companyId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}`;
  }

  static getCompanyLocations(companyId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations`;
  }

  static getCompanyLocation(companyId: number, locationId: number): string {    
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}`;
  }

  static updateCompanyLocation(companyId: number, locationId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}`;
  }

  static updateCompanyLocationEmailConfiguration(companyId: Number, locationId: Number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/locationSmpt`;
  }

  static createCompanyLocation(companyId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations`;
  }

  static updateCompany(companyId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}`;
  }

  static createCompany(): string {
    return `${environment.mainServiceUrl}/companies`;
  }

  static createCompanyLogo(companyId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/companyLogos`;
  }

  static deleteCompanyLogo(companyLogoId: string): string {
    return `${environment.mainServiceUrl}/companyLogos/${companyLogoId}`;
  }

  static getCompanyLogos(companyId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/companyLogos`;
  }

  static getCompanyPreviews(): string {
    return `${environment.mainServiceUrl}/companypreviews`;
  }

  // company integration

  static getCompanyIntegrations(companyId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/integrations`;
  }

  static createCompanyIntegration(companyId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/integrations`;
  }

  static updateCompanyIntegration(companyId: number, integrationId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/integrations/${integrationId}`;
  }

  static deleteCompanyIntegration(companyId: number, integrationId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/integrations/${integrationId}`;
  }

  // location integration

  static getCompanyLocationIntegrations(companyId: number, locationId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/integrations`;
  }

  static createCompanyLocationIntegration(companyId: number, locationId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/integrations`;
  }

  static updateCompanyLocationIntegration(companyId: number, locationId: number, integrationId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/integrations/${integrationId}`;
  }

  static deleteCompanyLocationIntegration(companyId: number, locationId: number, integrationId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/integrations/${integrationId}`;
  }

  // pickup times

  static getCompanyLocationPickupTimes(companyId: number, locationId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/pickuptimes`;
  }

  static createCompanyLocationPickupTime(companyId: number, locationId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/pickuptimes`;
  }

  static updateCompanyLocationPickupTime(companyId: number, locationId: number, pickupTimeId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/pickuptimes/${pickupTimeId}`;
  }

  static deleteCompanyLocationPickupTime(companyId: number, locationId: number, pickupTimeId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/pickuptimes/${pickupTimeId}`;
  }

  // delivery zones

  static getCompanyLocationDeliveryZones(companyId: number, locationId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/deliveryzones`;
  }

  static createCompanyLocationDeliveryZone(companyId: number, locationId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/deliveryzones`;
  }

  static updateCompanyLocationDeliveryZone(companyId: number, locationId: number, deliveryZoneId: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/deliveryzones/${deliveryZoneId}`;
  }
  
  // Distance Range

  static getCompanyLocationDistanceRange(companyId: number, locationId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/distanceRanges`;
  }

  static createCompanyLocationDistanceRange(companyId: number, locationId: number): string {    
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/distanceRanges`;
  }

  static updateCompanyLocationDistanceRange(companyId: number, locationId: number, distanceRangesId: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/distanceRanges/${distanceRangesId}`;
  }

  // Dimension Range

  static getCompanyLocationDimensionRange(companyId: number, locationId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/dimensionRanges`;
  }

  static createCompanyLocationDimensionRange(companyId: number, locationId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/dimensionRanges`;
  }

  static updateCompanyLocationDimensionRange(companyId: number, locationId: number, dimensionRangesId: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/dimensionRanges/${dimensionRangesId}`;
  }

  // Weight Range

  static getCompanyLocationWeightRange(companyId: number, locationId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/weightRanges`;
  }

  static createCompanyLocationWeightRange(companyId: number, locationId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/weightRanges`;
  }

  static updateCompanyLocationWeightRange(companyId: number, locationId: number, weightRangesId: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/weightRanges/${weightRangesId}`;
  }

  // delivery zone rates

  static getCompanyLocationDeliveryZoneRates(companyId: number, locationId: number, deliveryZoneId: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/deliveryzones/${deliveryZoneId}/rates`;
  }

  static createCompanyLocationDeliveryZoneRate(companyId: number, locationId: number, deliveryZoneId: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/deliveryzones/${deliveryZoneId}/rates`;
  }

  static updateCompanyLocationDeliveryZoneRate(companyId: number, locationId: number,
                                               deliveryZoneId: string, deliveryZoneRateId: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/deliveryzones/${deliveryZoneId}/rates/${deliveryZoneRateId}`;
  }

  static deleteCompanyLocationDeliveryZoneRate(companyId: number, locationId: number,
                                               deliveryZoneId: string, deliveryZoneRateId: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/deliveryzones/${deliveryZoneId}/rates/${deliveryZoneRateId}`;
  }

  // Roles
  static getCompanyRoles(companyId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/roles`;
  }

  static getRoleById(companyId: number, roleId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/roles/${roleId}`;
  }

  static updateCompanyRole(companyId: number, roleId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/roles/${roleId}`;
  }

  static createCompanyRole(companyId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/roles/`;
  }

  // Pickup Location Cities
  static getPickupLocationCities(): string {
    return `${environment.mainServiceUrl}/pickuplocationcities`;
  }

  // Lookup Types
  static getPermissionTypes(): string {
    return `${environment.mainServiceUrl}/permissiontypes`;
  }
  static getAttachmentTypes(): string {
    return `${environment.mainServiceUrl}/attachmenttypes`;
  }

  static getCountryTypes(): string {
    return `${environment.mainServiceUrl}/countrytypes`;
  }

  static getCurrencyTypes(): string {
    return `${environment.mainServiceUrl}/currencytypes`;
  }
  static getCurrencyById(id:number): string {
    return `${environment.mainServiceUrl}/currencytypes/${id}`;
  }

  static getIntegrationTypes(): string {
    return `${environment.mainServiceUrl}/integrationTypes`;
  }

  static getDeliveryMinimumFrequencyTypes(): string {
    return `${environment.mainServiceUrl}/deliveryminimumfrequencytypes`;
  }

  static getVehicleTypes(): string {
    return `${environment.mainServiceUrl}/vehicletypes`;
  }

  static getExtraChargeTypes(): string {
    return `${environment.mainServiceUrl}/extrachargetypes`;
  }
  static getLicenseClassTypes(): string {
    return `${environment.mainServiceUrl}/driverlicenseclasstypes`;
  }
  static getProofOfWorkEligibilityTypes(): string {
    return `${environment.mainServiceUrl}/driverproofofworkeligibilitytypes`;
  }
  static getDriverProvincialRequirementLinkTypes(): string {
    return `${environment.mainServiceUrl}/driverprovincialrequirementlinktypes`;
  }
  // Taxes
  static getTaxes(): string {
    return `${environment.mainServiceUrl}/taxes`;
  }

  static createTax(): string {
    return `${environment.mainServiceUrl}/taxes`;
  }

  static getTax(taxId: number): string {
    return `${environment.mainServiceUrl}/taxes/${taxId}`;
  }

  static updateTax(taxId: number): string {
    return `${environment.mainServiceUrl}/taxes/${taxId}`;
  }

  static getSmtpDetails(): string {
    return `${environment.mainServiceUrl}/locationSmtpType`;
  }
  static getSmtpFormDetails(companyId,locationId,smtpTypeId): string{
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/smtpTypeId/${smtpTypeId}`;
  }
  static getAllEmailTemplateType():string{
    return `${environment.mainServiceUrl}/emailTemplateType`
  }

  static getSmtpValidation():string{
    return `${environment.mainServiceUrl}/smptValidation`
  }

  static getEmailTemplateById(companyId,locationId,emailTemplateId): string{
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/emailTemplateByTypeId/${emailTemplateId}`;
  }

  static getSMSFormDetails(companyId,locationId,smsTypeId,smsServiceProviderTypeId): string{
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/smsTypeId/${smsTypeId}?smsServiceProviderTypeId=${smsServiceProviderTypeId}`;
  }

  static getLocationSMSTypes(): string {
    return `${environment.mainServiceUrl}/locationSmsType`;
  }

  static getSMSServiceProviderTypes(): string {
    return `${environment.mainServiceUrl}/SMSServiceProviderType`;
  }

  static getAllSMSTemplateType():string{
    return `${environment.mainServiceUrl}/smsTemplateType`
  }

  static getSMSTemplateById(companyId,locationId,smsTemplateId): string{
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/smsTemplateByTypeId/${smsTemplateId}`;
  }

  // Drivers
  static inviteDriverOnBepo(id?: number): string {
    return `${environment.mainServiceUrl}/drivers/bepoinvite/${id}`;
  }

  static updateBepoStatus(id: number): string {
    return `${environment.mainServiceUrl}/drivers/updatebepostatus/${id}`;
  }

  static getDrivers(onboardingStatuses?: number[]): string {
    return `${environment.mainServiceUrl}/drivers?onboardingStatuses=${onboardingStatuses}`;
  }

  static createDriver(): string {
    return `${environment.mainServiceUrl}/drivers`;
  }

  static registerDriver(): string {
    return `${environment.mainServiceUrl}/drivers/registerDriver`;
  }

  static getDriver(driverId: number): string {
    return `${environment.mainServiceUrl}/drivers/${driverId}`;
  }

  static updateDriver(driverId: number): string {
    return `${environment.mainServiceUrl}/drivers/${driverId}`;
  }

  static getDriverWeeklyCompensation(driverId: number): string {
    return `${environment.mainServiceUrl}/drivers/${driverId}/weeklycompensation`;
  }

  static getDriverWeeklyCompensationById(driverId: number, weeklyCompensationId: string): string {
    return `${environment.mainServiceUrl}/drivers/${driverId}/weeklycompensation/${weeklyCompensationId}`;
  }

  static updateDriverWeeklyCompensation(driverId: number, weeklyCompensationId: string): string {
    return `${environment.mainServiceUrl}/drivers/${driverId}/weeklycompensation/${weeklyCompensationId}`;
  }

  static getDriverManualCompensation(driverId: number): string {
    return `${environment.mainServiceUrl}/drivers/${driverId}/manualcompensation`;
  }

  static createDriverManualCompensation(driverId: number): string {
    return `${environment.mainServiceUrl}/drivers/${driverId}/manualcompensation`;
  }

  static updateDriverManualCompensation(driverId: number, manualCompensationId: string): string {
    return `${environment.mainServiceUrl}/drivers/${driverId}/manualcompensation/${manualCompensationId}`;
  }

  static deleteDriverManualCompensation(driverId: number, manualCompensationId: string): string {
    return `${environment.mainServiceUrl}/drivers/${driverId}/manualcompensation/${manualCompensationId}`;
  }

  static getDriverHours() {
    return `${environment.mainServiceUrl}/driverhoursworked`;
  }
  static updateDriverHours() {
    return `${environment.mainServiceUrl}/driverhoursworked`;
  }

  static getTeams(): string {
    return `${environment.mainServiceUrl}/teams`;
  }

  static getTeamById(teamId: number): string {
    return `${environment.mainServiceUrl}/teams/${teamId}`;
  }

  static createTeam(): string {
    return `${environment.mainServiceUrl}/teams`;
  }

  static updateTeam(teamId: number): string {
    return `${environment.mainServiceUrl}/teams/${teamId}`;
  }

  static deleteTeam(teamId: number): string {
    return `${environment.mainServiceUrl}/teams/${teamId}`;
  }

  static submitOnboardingStatus(status: {onboardingStatusId: OnboardingStatus, driverId: number}) {
    return `${environment.mainServiceUrl}/drivers/${status.driverId}/onboardingstatus`;
  }

  static getDriverApplicants(submittedStartDate?: string, submittedEndDate?: string): string {
    return `${environment.mainServiceUrl}/driverapplicants?SubmittedDate[gte]=${submittedStartDate}&SubmittedDate[lte]=${submittedEndDate}`;
  }


  // vehicle
  static vehicles(urlData: {id: number}) {
    return `${environment.mainServiceUrl}/drivers/${urlData.id}/vehicles`;
  }
  static createDriverVehicle(urlData: {id: number}) {
    return Endpoints.vehicles(urlData);
  }
  static updateDriverVehicle(urlData: {id: number, vehicleId: number}) {
    return `${environment.mainServiceUrl}/drivers/${urlData.id}/vehicles/${urlData.vehicleId}`;
  }
  static createVehicleAttachment(urlData: {id: number, driverId: number}) {
    return `${environment.mainServiceUrl}/drivers/${urlData.driverId}/vehicles/${urlData.id}/attachments`;
  }
  static getVehicle(urlData: {driverId: number, id: number}) {
    return `${environment.mainServiceUrl}/drivers/${urlData.driverId}/vehicles/${urlData.id}`;
  }
  static getVehicleAttachments(urlData: {id: number, vehicleId: number}) {
    return `${environment.mainServiceUrl}/drivers/${urlData.id}/vehicles/${urlData.vehicleId}/attachments`;
  }
  static getVehicleAttachmentsById(urlData: {id: number, vehicleId: number, attachmentId: number}) {
    return `${environment.mainServiceUrl}/drivers/${urlData.id}/vehicles/${urlData.vehicleId}/attachments/${urlData.attachmentId}`;
  }
  static deleteVehicleAttachmentsById(driver: {id: number}, attachment: {vehicleId: number, id: string}) {
    return `${environment.mainServiceUrl}/drivers/${driver.id}/vehicles/${attachment.vehicleId}/attachments/${attachment.id}`;
  }


  static updateVehicleOnboardingComment(driver: DriverInterface, comment: OnboardingComment) {
    return `${environment.mainServiceUrl}/drivers/${driver.id}/vehicles/${comment.vehicleId}/onboardingcomments/${comment.id}`;
  }
  static createVehicleOnboardingComment(driverId: number, vehicleId) {
    return `${environment.mainServiceUrl}/drivers/${driverId}/vehicles/${vehicleId}/onboardingcomments`;
  }

  // Invoices
  static getInvoices(companyId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/invoices`;
  }

  static updateInvoice(companyId: number, invoiceId: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/invoices/${invoiceId}`;
  }

  static getHydratedInvoice(companyId: number, invoiceId: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/hydratedinvoices/${invoiceId}`;
  }

  static regenerateInvoices(companyId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/regenerateinvoices`;
  }

  // Orders
  static getDispatchNotes(orderId: string) {
    return `${environment.mainServiceUrl}/orders/${orderId}/dispatchnotes`;
  }

  static createDispatchNotes(orderId: string) {
    return `${environment.mainServiceUrl}/orders/${orderId}/dispatchnotes`;
  }

  static getDispatchNoteById(orderId: string, noteId: number) {
    return `${this.getDispatchNotes(orderId)}/${noteId}`;
  }


  static getOrdersForInvoice(invoiceId: string): string {
    return `${environment.mainServiceUrl}/orders?InvoiceId=${invoiceId}`;
  }

  static getOrder(orderId: string): string {
    return `${environment.mainServiceUrl}/orders/${orderId}`;
  }

  static deleteOrder(companyId: number, orderId: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/orders/${orderId}`;
  }

  static getOrdersForCompany(companyId: number): string {
    return `${environment.mainServiceUrl}/orders?CompanyId=${companyId}`;
  }

  static getOrdersForDriverDeliveryPeriod(driverId: number, deliveredStartDate: string, deliveredEndDate: string): string {
    return `${environment.mainServiceUrl}/orders?DriverId=${driverId}&DeliveredDate[gte]=${deliveredStartDate}&DeliveredDate[lte]=${deliveredEndDate}`;
  }

  static getOrdersForDriver(driverId: number): string {
    return `${environment.mainServiceUrl}/orders?driverId=${driverId}`;
  }

  static getLabelsForOrders(orderIds: string[]): string {
    return `${environment.mainServiceUrl}/orderlabels?orderIds=${orderIds.join(',')}`;
  }

  static getFilterOrders(): string {
    return `${environment.mainServiceUrl}/search-orders`;
  }

  // Extra Charges

  static createExtraCharge(companyId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/extracharges`;
  }

  static getExtraChargeById(companyId: number, chargeId: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/extracharges/${chargeId}`;
  }

  static getExtraChargesForCompany(companyId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/extracharges/`;
  }

  static getExtraChargeForInvoice(companyId: number, invoiceId: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/extracharges?InvoiceId=${invoiceId}`;
  }

  static updateExtraCharge(companyId: number, chargeId: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/extracharges/${chargeId}`;
  }

  static deleteExtraCharge(companyId: number, chargeId: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/extracharges/${chargeId}`;
  }

  static createOrders(companyId: number, locationId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/orders`;
  }

  static packageDeliveryCost(companyId: number, locationId: number,req:any): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/packageDeliveryCost`;
  }

  static orderDeliveryEstimatedCost(companyId: number, locationId: number, req:any): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/orderDeliveryEstimatedCost`;
  }

  static updateOrder(order: Order): string {
    return `${environment.mainServiceUrl}/companies/${order.companyId}/orders/${order.id}`;
  }

  static UpdateOrderWeight(request: OrderWeightChargeRequest): string {
    return `${environment.mainServiceUrl}/companies/${request.companyId}/locations/${request.locationId}/order/overweight-charge`;
  }

  static UpdateOrderToRush(request: RushOrderRequest): string {
    return `${environment.mainServiceUrl}/orders/${request.orderId}/update-to-rushorder`;
  }

  static createReturnOrder(order: ReturnOrder): string {
    return `${environment.mainServiceUrl}/companies/${order.companyId}/locations/${order.locationId}/returnOrder`;
  }

  static createHydratedDestination(companyId: number, locationId: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/destinations`;
  }  
  static getEstimatedRates(companyId: number, locationId: number, searchString: string, isRush: boolean, isOversize: boolean, numPackages: number, insuredAmount: number): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/deliveryzones/search?searchstring=${searchString}&isRush=${isRush}&isOversize=${isOversize}&numPackages=${numPackages}&insuredAmount=${insuredAmount}`;
  }
  static getRecipientsByPhoneNumber(companyId: number, locationId: number, phoneNumber: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/recipients?phoneNumber=${phoneNumber}`;
  }
  static getRecipientsByEmail(companyId: number, locationId: number, email: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/recipients?email=${email}`;
  }
  static getOrdersByOnFleetShortId(companyId: number, locationId: number, searchString: string): string {
    return `${environment.mainServiceUrl}/companies/${companyId}/locations/${locationId}/orders/search?searchString=${searchString}`;
  }

  // Client Alerts
  static getClientAlerts(){
    return `${environment.mainServiceUrl}/clientalerts`;
  }
  static getClientAlert(id: number){
    return `${environment.mainServiceUrl}/clientalerts/${id}`;
  }
  static deleteClientAlert(id: number){
    return `${environment.mainServiceUrl}/clientalerts/${id}`;
  }
  static saveClientAlert(id: number){
    return `${environment.mainServiceUrl}/clientalerts/${id}`;
  }
  static createClientAlert() {
    return `${environment.mainServiceUrl}/clientalerts`;
  }

  // City Fuel Surcharges
  static getCityFuelSurcharges(): string {
    return `${environment.mainServiceUrl}/cityfuelsurcharges`;
  }

  static createCityFuelSurcharge(): string {
    return `${environment.mainServiceUrl}/cityfuelsurcharges`;
  }

  static getCityFuelSurcharge(cityFuelSurchargeId: number): string {
    return `${environment.mainServiceUrl}/cityfuelsurcharges/${cityFuelSurchargeId}`;
  }

  static updateCityFuelSurcharge(cityFuelSurchargeId: number): string {
    return `${environment.mainServiceUrl}/cityfuelsurcharges/${cityFuelSurchargeId}`;
  }

  static searchOrdersByOnFleetShortId(onFleetShortId: string): string {
    return `${environment.mainServiceUrl}/orders/ByOnFleetShortID/${onFleetShortId}`;
  }

  static getTaskLocation(onFleetTaskId: string): string {
    return `${environment.mainServiceUrl}/tracking/task?id=${onFleetTaskId}`;
  }

  static getDriverCurrentLocation(driverWorkerId: string): string {
    return `${environment.mainServiceUrl}/tracking/worker?onfleetworkerid=${driverWorkerId}`;
  }

  static getPrimarycategorytypes(){
    return `${environment.mainServiceUrl}/primarycategorytypes`;
  }

  //Report
  static getClients(){
    return `${environment.mainServiceUrl}/report/getclients`;
  }
  static getclientlocations(companyId:any){
    return `${environment.mainServiceUrl}/company/${companyId}/report/getclientlocations`;
  }
  static searchEmailLog(request:EmailLogRequest){
    return `${environment.mainServiceUrl}/companies/${request.companyId}/searchEmailLog`;
  }

  static searchUnsubscribedEmails(request:UnsubscribeEmailRequest): string {
    return `${environment.mainServiceUrl}/companies/${request.companyId}/searchUnsubscribedEmail`;
  }

  static recipientOrderTrackingByOnFleetShortID(onFleetShortId: string): string {
    return `${environment.mainServiceUrl}/tracking/order?trackingId=${onFleetShortId}`;
  }

  static searchSMSLog(request:SMSLogRequest){
    return `${environment.mainServiceUrl}/companies/${request.companyId}/searchSMSLog`;
  }

  static searchUnsubscribedSMS(request:UnsubscribeSMSRequest): string {
    return `${environment.mainServiceUrl}/companies/${request.companyId}/searchUnsubscribedPhone`;
  }
}

